@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.as-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.25s ease-in-out;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
}
.as-popup .as-iframe-wrapper {
  position: relative;
  transition: opacity 0.25s ease-in-out;
  min-width: 360px;
  min-height: 360px;
}
.as-popup .as-iframe-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  border-radius: 8px;
}
.as-popup .as-close {
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  font-size: 32px;
  font-weight: normal;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.25s ease-in-out;
  text-decoration: none;
  color: #000;
  top: -34px;
  right: 0;
  background: none;
  border: none;
  border-radius: 0;
}
.as-popup .as-close:hover {
  opacity: 1;
}
@media (min-width: 481px) {
  .as-popup .as-close {
    color: #fff !important;
  }
}
.as-popup .as-spinner {
  border: 3px solid #aaa;
  font-size: 40px;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
  border-top-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}

@media (max-width: 480px) {
  .as-popup {
    width: 100% !important;
    height: 100% !important;
    max-height: stretch;
  }
  .as-popup .as-iframe-wrapper {
    position: relative;
    transition: opacity 0.25s ease-in-out;
    min-width: 100%;
    min-height: 100%;
  }
  .as-popup .as-iframe-wrapper iframe {
    border-radius: 0;
  }
  .as-popup .as-close {
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    font-size: 32px;
    font-weight: normal;
    line-height: 24px;
    width: 24px;
    height: 24px;
    text-align: center;
    text-transform: none;
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.25s ease-in-out;
    text-decoration: none;
    color: #000;
    top: 6px;
    right: 8px;
    background: none;
    border: none;
    border-radius: 0;
  }
  .as-popup .as-close:hover {
    opacity: 1;
  }
}
@media (max-width: 480px) and (min-width: 481px) {
  .as-popup .as-close {
    color: #fff !important;
  }
}