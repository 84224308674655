@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.as-sidetab {
  position: fixed;
  top: 50%;
  right: 0;
  width: 400px;
  height: 580px;
  transform: translate(100%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
  z-index: 10001;
  will-change: transform;
}
.as-sidetab.ready {
  transition: transform 400ms cubic-bezier(0.5, 0, 0.75, 0);
}
.as-sidetab iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  border-radius: 8px 0 0 8px;
}
.as-sidetab.open {
  transform: translate(0, -50%);
}
.as-sidetab-wrapper {
  position: relative;
  height: 100%;
}
.as-sidetab-button {
  position: absolute;
  top: 50%;
  left: -38px;
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left top;
  min-width: 100px;
  max-width: 540px;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 12px rgba(0, 0, 0, 0.06);
  background-color: #00243d;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  outline: none;
}
.as-sidetab-button-text {
  flex: 1;
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.as-sidetab-button-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  transform: rotate(90deg);
  margin-right: 12px;
  position: relative;
  order: -1;
  color: #fff;
}
.as-sidetab-button-icon > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.as-sidetab-close {
  display: none;
}
.as-sidetab .as-spinner {
  border: 3px solid #aaa;
  font-size: 18px;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
  border-top-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -9px 0 0 -9px;
  top: 0;
  left: 0;
  margin: 0;
}

@media (max-width: 480px) {
  .as-sidetab {
    transition: unset;
  }
  .as-sidetab.ready {
    transition: unset;
  }
  .as-sidetab.open {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0, 0);
    width: 100% !important;
    height: 100% !important;
    max-height: stretch;
  }
  .as-sidetab-close {
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    font-size: 32px;
    font-weight: normal;
    line-height: 24px;
    width: 24px;
    height: 24px;
    text-align: center;
    text-transform: none;
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.25s ease-in-out;
    text-decoration: none;
    color: #000;
    top: 6px;
    right: 8px;
    background: none;
    border: none;
    border-radius: 0;
    display: block;
    z-index: 1;
  }
  .as-sidetab-close:hover {
    opacity: 1;
  }
}
@media (max-width: 480px) and (min-width: 481px) {
  .as-sidetab-close {
    color: #fff !important;
  }
}