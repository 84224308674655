/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@maersk-global/mds-foundations/css/foundations';

@import '../node_modules/@maersk-global/insightshub-embed-common/src/css/popup.css';

body {
  font-family: 'Maersk Text';
  margin: 0;
  background-color: var(
    --mds_brand_appearance_neutral_subtle_background-color
  ) !important;

  // Toaster container should be on top of all other elements. Our header has z-index = 100 so adding this as 101 here.
  .mc-toast-container {
    z-index: 101;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: inset 0 0 4px
    var(--mds_foundations_table_header-cell_sort_active_background-color);
  background-color: var(--mds_foundations_table_header-cell_background-color);
}

::-webkit-scrollbar-thumb {
  background: var(
    --mds_foundations_table_header-cell_sort_active_background-color
  );
  border-radius: 4px;

  &:hover {
    background-color: var(--mds_brand_appearance_neutral_weak_text-color);
  }
}

::-webkit-scrollbar-corner {
  background-color: var(--mds_foundations_table_header-cell_background-color);
}
